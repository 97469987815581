import React, { Component } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { Link as ScrollLink, scroller } from 'react-scroll';
import { NavMenu } from './NavMenu';
import { Grid, Fab, Typography, TextField, Button, Tooltip } from '@mui/material';
import { LanguageContext } from './LanguageContext';

import { Timeline, Public, Equalizer, Code, Language, PinDrop, BurstMode, FolderShared, Storage, School, Group, FiberManualRecord } from '@mui/icons-material';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Paper from '@mui/material/Paper';
import { withStyles } from "@mui/styles";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';
import Container from '@mui/material/Container';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import PaymentIcon from '@mui/icons-material/Payment';
import Person2Icon from '@mui/icons-material/Person2';
import HelpIcon from '@mui/icons-material/Help';

import GlobalMenu from "./GlobalMenu"
import KayttajaProfiili from "./KayttajaProfiili"
import Laskutus from "./Laskutus"
import Support from "./Support"

export class KayttajaTili extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);

        this.state = {
            openUser: false,
            openBilling: false,
            openSupport: false,
            plan: "A"
        };
    }

    OpenNewWindow = (e) => {
        e.preventDefault();

        var url = null
        fetch('api/Login/GetLink')
            .then(response => response.text())
            .then(data => {
                url = data;
                if (url) {
                    window.open(url, '_blank').focus();
                }
            });

    }

    handleLogEvent = (e, tar, act, info, url) => {
        e.preventDefault();

        fetch('api/EventLoger/Create', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                target: tar,
                action: act,
                info: info
            })
        })

        if (url) {
            window.open(url, '_blank').focus();
        }
    }

    render(props) {
        const { userLanguage, dictionary } = this.context;

        const handleChangePlan = (event) => {
            this.setState({ plan: event.target.value });
        };

        return (
            <div>
                <div style={{ display: "flex", padding: "25px 30px" }}>
                    <div>
                        <Typography variant="h2" color="iconText" style={{ fontSize: "36px" }}>Käyttäjäprofiili</Typography>
                        <Typography variant="subtitle1" color="iconText">Subheader teksti tähän</Typography>

                        <List component="div" disablePadding>
                            <KayttajaProfiili />
                            <Laskutus />
                            <Support />
                        </List>
                    </div>
                </div>
            </div>
        );
    }
}