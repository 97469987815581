// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { BoxAndWhiskerCategory } from '../geoservice/box-and-whisker-category';


export class BoxAndWhiskerMeasure {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):BoxAndWhiskerMeasure {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBoxAndWhiskerMeasure(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerMeasure):BoxAndWhiskerMeasure {
  return (obj || new BoxAndWhiskerMeasure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBoxAndWhiskerMeasure(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerMeasure):BoxAndWhiskerMeasure {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BoxAndWhiskerMeasure()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

label():string|null
label(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
label(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

description():string|null
description(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
description(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

categories(index: number, obj?:BoxAndWhiskerCategory):BoxAndWhiskerCategory|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new BoxAndWhiskerCategory()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

categoriesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startBoxAndWhiskerMeasure(builder:flatbuffers.Builder) {
  builder.startObject(3);
}

static addLabel(builder:flatbuffers.Builder, labelOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, labelOffset, 0);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, descriptionOffset, 0);
}

static addCategories(builder:flatbuffers.Builder, categoriesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, categoriesOffset, 0);
}

static createCategoriesVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startCategoriesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endBoxAndWhiskerMeasure(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static finishBoxAndWhiskerMeasureBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset);
}

static finishSizePrefixedBoxAndWhiskerMeasureBuffer(builder:flatbuffers.Builder, offset:flatbuffers.Offset) {
  builder.finish(offset, undefined, true);
}

static createBoxAndWhiskerMeasure(builder:flatbuffers.Builder, labelOffset:flatbuffers.Offset, descriptionOffset:flatbuffers.Offset, categoriesOffset:flatbuffers.Offset):flatbuffers.Offset {
  BoxAndWhiskerMeasure.startBoxAndWhiskerMeasure(builder);
  BoxAndWhiskerMeasure.addLabel(builder, labelOffset);
  BoxAndWhiskerMeasure.addDescription(builder, descriptionOffset);
  BoxAndWhiskerMeasure.addCategories(builder, categoriesOffset);
  return BoxAndWhiskerMeasure.endBoxAndWhiskerMeasure(builder);
}
}
