// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class HistogramBin {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):HistogramBin {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

lowerbound():number {
  return this.bb!.readFloat32(this.bb_pos);
}

upperbound():number {
  return this.bb!.readFloat32(this.bb_pos + 4);
}

count():number {
  return this.bb!.readInt32(this.bb_pos + 8);
}

static sizeOf():number {
  return 12;
}

static createHistogramBin(builder:flatbuffers.Builder, lowerbound: number, upperbound: number, count: number):flatbuffers.Offset {
  builder.prep(4, 12);
  builder.writeInt32(count);
  builder.writeFloat32(upperbound);
  builder.writeFloat32(lowerbound);
  return builder.offset();
}

}
