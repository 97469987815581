// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

import { BoxAndWhiskerValues } from '../geoservice/box-and-whisker-values';
import { HistogramBin } from '../geoservice/histogram-bin';


export class BoxAndWhiskerCategory {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):BoxAndWhiskerCategory {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBoxAndWhiskerCategory(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerCategory):BoxAndWhiskerCategory {
  return (obj || new BoxAndWhiskerCategory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBoxAndWhiskerCategory(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerCategory):BoxAndWhiskerCategory {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BoxAndWhiskerCategory()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

label():string|null
label(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
label(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

description():string|null
description(optionalEncoding:flatbuffers.Encoding):string|Uint8Array|null
description(optionalEncoding?:any):string|Uint8Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 6);
  return offset ? this.bb!.__string(this.bb_pos + offset, optionalEncoding) : null;
}

histogram(index: number, obj?:HistogramBin):HistogramBin|null {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? (obj || new HistogramBin()).__init(this.bb!.__vector(this.bb_pos + offset) + index * 12, this.bb!) : null;
}

histogramLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 8);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valuebins(index: number, obj?:BoxAndWhiskerValues):BoxAndWhiskerValues|null {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? (obj || new BoxAndWhiskerValues()).__init(this.bb!.__indirect(this.bb!.__vector(this.bb_pos + offset) + index * 4), this.bb!) : null;
}

valuebinsLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 10);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

static startBoxAndWhiskerCategory(builder:flatbuffers.Builder) {
  builder.startObject(4);
}

static addLabel(builder:flatbuffers.Builder, labelOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, labelOffset, 0);
}

static addDescription(builder:flatbuffers.Builder, descriptionOffset:flatbuffers.Offset) {
  builder.addFieldOffset(1, descriptionOffset, 0);
}

static addHistogram(builder:flatbuffers.Builder, histogramOffset:flatbuffers.Offset) {
  builder.addFieldOffset(2, histogramOffset, 0);
}

static startHistogramVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(12, numElems, 4);
}

static addValuebins(builder:flatbuffers.Builder, valuebinsOffset:flatbuffers.Offset) {
  builder.addFieldOffset(3, valuebinsOffset, 0);
}

static createValuebinsVector(builder:flatbuffers.Builder, data:flatbuffers.Offset[]):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addOffset(data[i]!);
  }
  return builder.endVector();
}

static startValuebinsVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endBoxAndWhiskerCategory(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBoxAndWhiskerCategory(builder:flatbuffers.Builder, labelOffset:flatbuffers.Offset, descriptionOffset:flatbuffers.Offset, histogramOffset:flatbuffers.Offset, valuebinsOffset:flatbuffers.Offset):flatbuffers.Offset {
  BoxAndWhiskerCategory.startBoxAndWhiskerCategory(builder);
  BoxAndWhiskerCategory.addLabel(builder, labelOffset);
  BoxAndWhiskerCategory.addDescription(builder, descriptionOffset);
  BoxAndWhiskerCategory.addHistogram(builder, histogramOffset);
  BoxAndWhiskerCategory.addValuebins(builder, valuebinsOffset);
  return BoxAndWhiskerCategory.endBoxAndWhiskerCategory(builder);
}
}
