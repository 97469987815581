// automatically generated by the FlatBuffers compiler, do not modify

import * as flatbuffers from 'flatbuffers';

export class BoxAndWhiskerValues {
  bb: flatbuffers.ByteBuffer|null = null;
  bb_pos = 0;
__init(i:number, bb:flatbuffers.ByteBuffer):BoxAndWhiskerValues {
  this.bb_pos = i;
  this.bb = bb;
  return this;
}

static getRootAsBoxAndWhiskerValues(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerValues):BoxAndWhiskerValues {
  return (obj || new BoxAndWhiskerValues()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

static getSizePrefixedRootAsBoxAndWhiskerValues(bb:flatbuffers.ByteBuffer, obj?:BoxAndWhiskerValues):BoxAndWhiskerValues {
  bb.setPosition(bb.position() + flatbuffers.SIZE_PREFIX_LENGTH);
  return (obj || new BoxAndWhiskerValues()).__init(bb.readInt32(bb.position()) + bb.position(), bb);
}

values(index: number):number|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.readFloat32(this.bb!.__vector(this.bb_pos + offset) + index * 4) : 0;
}

valuesLength():number {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? this.bb!.__vector_len(this.bb_pos + offset) : 0;
}

valuesArray():Float32Array|null {
  const offset = this.bb!.__offset(this.bb_pos, 4);
  return offset ? new Float32Array(this.bb!.bytes().buffer, this.bb!.bytes().byteOffset + this.bb!.__vector(this.bb_pos + offset), this.bb!.__vector_len(this.bb_pos + offset)) : null;
}

static startBoxAndWhiskerValues(builder:flatbuffers.Builder) {
  builder.startObject(1);
}

static addValues(builder:flatbuffers.Builder, valuesOffset:flatbuffers.Offset) {
  builder.addFieldOffset(0, valuesOffset, 0);
}

static createValuesVector(builder:flatbuffers.Builder, data:number[]|Float32Array):flatbuffers.Offset;
/**
 * @deprecated This Uint8Array overload will be removed in the future.
 */
static createValuesVector(builder:flatbuffers.Builder, data:number[]|Uint8Array):flatbuffers.Offset;
static createValuesVector(builder:flatbuffers.Builder, data:number[]|Float32Array|Uint8Array):flatbuffers.Offset {
  builder.startVector(4, data.length, 4);
  for (let i = data.length - 1; i >= 0; i--) {
    builder.addFloat32(data[i]!);
  }
  return builder.endVector();
}

static startValuesVector(builder:flatbuffers.Builder, numElems:number) {
  builder.startVector(4, numElems, 4);
}

static endBoxAndWhiskerValues(builder:flatbuffers.Builder):flatbuffers.Offset {
  const offset = builder.endObject();
  return offset;
}

static createBoxAndWhiskerValues(builder:flatbuffers.Builder, valuesOffset:flatbuffers.Offset):flatbuffers.Offset {
  BoxAndWhiskerValues.startBoxAndWhiskerValues(builder);
  BoxAndWhiskerValues.addValues(builder, valuesOffset);
  return BoxAndWhiskerValues.endBoxAndWhiskerValues(builder);
}
}
